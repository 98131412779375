












































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { breadcrumb } from "@/utils/breadcrumb";
import {
  ApiElectronicCaseList,
  ApiLabelList,
  ApiCaseLabelSave,
  ApiBasicInformationSave,
  ApiBasicInfoCheckName,
  ApiGetllnessList,
  ApiGetProvince,
  ApiGetCity,
  ApiGetArea,
  ApiBasicInformationDel,
} from "@/apis/index";
import { checkIdCard, getAgeFromIDCard } from "@/utils";
import * as utils from "@/utils";

import { Form, Timeline } from "element-ui";
import { RouteConfig } from "vue-router";
import { ElStep } from "element-ui/types/step";
Component.registerHooks(["beforeRouteEnter"]);
@Component({
  name: "PatientCase",
})
export default class PatientCase extends Vue {
  beforeRouteEnter(to: RouteConfig, from: RouteConfig, next: Function): void {
    const name: any = from.name;
    // 进入组件之前未被实例化，无法直接访问this
    next((vm: any) => {
      const list = new Set(["viewPatientCase"]);
      const need_cache: boolean = list.has(name);
      if (!need_cache) {
        vm.resetCondition();
      } else {
        vm.updateTableData();
      }
    });
  }
  tableLoading = false;
  //data
  condition: any = {
    user_name: "",
    user_sex: "",
    user_tel: "",
    user_age: "",
    user_process: "",
    start_createdate: "",
    end_createdate: "",
    name: "",
    user_disease: [],
  };
  //性别下拉框列表
  sexList = [
    {
      label: "全部",
      value: "",
    },
    {
      label: "男",
      value: "男",
    },
    {
      label: "女",
      value: "女",
    },
  ];
  //标签下拉框列表
  labelList: any[] = [];
  tableData: Array<any> = [];
  pagination = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };

  dialogAdd = false;
  currentAddr = [];
  caseId = null;

  homeland = ["中国", "澳大利亚", "新加坡"];

  basicInformationForm = {
    user_id: "",
    user_name: "",
    user_sex: "",
    user_tel: "",
    user_idtype: "",
    user_idcardnum: "",
    // user_birthday: "",
    age: "",
    user_education: "",
    user_height: "",
    user_weight: "",
    province_code: "",
    city_code: "",
    area_code: "",
    user_communicationaddr: "",
    disease_date: "",
    user_disease: "",
    user_censusAddr: "",
    user_country: "",
    user_nationality: "汉族",
    user_province: "",
    user_city: "",
    user_occupation: "",
    user_marriage: "",
  } as any;

  passportRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
  ];
  idCardRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
    {
      pattern:
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
      message: "请输入正确的身份证号",
      trigger: "blur",
    },
  ];
  sexListDialod = [
    {
      label: "男",
      value: "男",
    },
    {
      label: "女",
      value: "女",
    },
  ];
  documentsList = [
    {
      label: "身份证",
      value: "身份证",
    },
    {
      label: "护照",
      value: "护照",
    },
  ];
  educationLevellist = [
    "未知",
    "本科",
    "研究生",
    "大专",
    "中专",
    "技校",
    "高中",
    "初中",
    "小学",
    "文盲",
  ];
  nation = ["汉族", "回族", "满族", "其它"];
  position = ["工人", "教师", "农民", "干部", "学生", "职员", "退休", "其它"];
  marry = ["未婚", "已婚", "初婚", "再婚", "复婚", "丧偶", "离婚", "未知"];
  currentIllnessList = [];

  currentAddressProps = {
    lazy: true,
    lazyLoad(node: any, resolve: any) {
      let result = [];
      let param = {};
      //获取当前层级
      const { level } = node;
      if (level == 0) {
        ApiGetProvince(param).then((res) => {
          if (res.success) {
            // code: "34"
            // first_letter: "A"
            // name: "安徽省"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
              };
            });
            resolve(result);
          }
        });
      } else if (level == 1) {
        param = {
          province_code: node.value,
        };
        ApiGetCity(param).then((res) => {
          if (res.success) {
            // code: "3408"
            // first_letter: "A"
            // name: "安庆市"
            // province_code: "34"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
              };
            });
            resolve(result);
          }
        });
      } else if (level == 2) {
        param = {
          city_code: node.value,
        };
        ApiGetArea(param).then((res) => {
          if (res.success) {
            // city_code: "3408"
            // code: "340871"
            // first_letter: "A"
            // name: "安徽安庆经济开发区"
            // province_code: "34"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
                leaf: level >= 2,
              };
            });
            resolve(result);
          }
        });
      }
    },
  };
  basicInformationRules = {
    user_name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
    user_sex: [{ required: true, message: "请选择患者性别", trigger: "blur" }],
    user_tel: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
    // user_birthday: [
    //   { required: true, message: "请选择出生日期", trigger: "blur" },
    // ],
    age: [{ required: true, message: "请填写年龄", trigger: "blur" }],
    user_idcardnum: this.passportRule,
    disease_date: [
      { required: true, message: "请选择发病年份", trigger: "blur" },
    ],
    user_disease: [
      { required: true, message: "请选择当前所患疾病", trigger: "blur" },
    ],
  };
  saveBasicInformation() {
    const currentAddrList = this.currentAddr;
    const _this = this;
    if (currentAddrList.length == 3) {
      this.basicInformationForm.province_code = currentAddrList[0];
      this.basicInformationForm.city_code = currentAddrList[1];
      this.basicInformationForm.area_code = currentAddrList[2];
    } else {
      this.basicInformationForm.province_code = "";
      this.basicInformationForm.city_code = "";
      this.basicInformationForm.area_code = "";
    }
    const formRef = this.$refs.basicInformationRuleForm as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        // if (this.caseId) {
        //   this.basicInformationForm.user_id = this.caseId;
        // }
        const param = {
          ...this.basicInformationForm,
        };
        param.age = Number(param.age);

        param.user_disease = JSON.stringify(
          this.basicInformationForm.user_disease
        );
        //如果有填写证件号码，需要判断证件类型，没有证件号码直接提交
        if (this.basicInformationForm.user_idcardnum) {
          //如果证件类型是护照，不进行验证,直接调用新增基础信息接口
          if (this.basicInformationForm.user_idtype == "护照") {
            const par = {
              doctor_id: (utils.get("doctorLoginInfo") as any).id,
              user_name: this.basicInformationForm.user_name,
              user_id: this.basicInformationForm.user_id,
            };
            ApiBasicInfoCheckName(par).then((ress) => {
              if (!ress.data.duplication_name) {
                ApiBasicInformationSave(param).then((res) => {
                  if (res.success) {
                    this.caseId = res.data;
                    this.$message.success("保存成功！");
                    this.cancelFun();
                    _this.init();
                  }
                });
              } else {
                this.$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }).then(async () => {
                  ApiBasicInformationSave(param).then((res) => {
                    if (res.success) {
                      this.caseId = res.data;
                      this.$message.success("保存成功！");
                      this.cancelFun();
                      _this.init();
                    }
                  });
                });
              }
            });
          } else {
            //如果证件类型是身份证 校验身份证与姓名是否匹配
            checkIdCard(
              this.basicInformationForm.user_idcardnum,
              this.basicInformationForm.user_name,
              function (res: any) {
                if (res != "01") {
                  _this.$message.warning(res);
                  return;
                }
                const par = {
                  doctor_id: (utils.get("doctorLoginInfo") as any).id,
                  user_name: _this.basicInformationForm.user_name,
                  user_id: _this.basicInformationForm.user_id,
                };
                ApiBasicInfoCheckName(par).then((ress) => {
                  if (!ress.data.duplication_name) {
                    ApiBasicInformationSave(param).then((res) => {
                      if (res.success) {
                        _this.caseId = res.data;
                        _this.$message.success("保存成功！");
                        _this.init();
                        _this.cancelFun();
                      }
                    });
                  } else {
                    _this
                      .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      })
                      .then(async () => {
                        ApiBasicInformationSave(param).then((res) => {
                          if (res.success) {
                            _this.caseId = res.data;
                            _this.$message.success("保存成功！");
                            _this.init();
                            _this.cancelFun();
                          }
                        });
                      });
                  }
                });
              }
            );
          }
        } else {
          const par = {
            doctor_id: (utils.get("doctorLoginInfo") as any).id,
            user_name: _this.basicInformationForm.user_name,
            user_id: _this.basicInformationForm.user_id,
          };
          ApiBasicInfoCheckName(par).then((ress) => {
            if (!ress.data.duplication_name) {
              ApiBasicInformationSave(param).then((res) => {
                if (res.success) {
                  _this.caseId = res.data;
                  localStorage.setItem("database_id", res.data);
                  //   _this.canUpload = true;
                  _this.cancelFun();
                  _this.$message.success("保存成功！");
                  _this.init();
                }
              });
            } else {
              _this
                .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(async () => {
                  ApiBasicInformationSave(param).then((res) => {
                    if (res.success) {
                      _this.caseId = res.data;
                      localStorage.setItem("database_id", res.data);
                      //   _this.canUpload = true;
                      _this.cancelFun();
                      _this.$message.success("保存成功！");
                      _this.init();
                    }
                  });
                });
            }
          });
        }
      }
    });
  }
  dealCard() {
    const idCardNum = this.basicInformationForm.user_idcardnum;
    if (
      idCardNum.length === 18 &&
      this.basicInformationForm.user_idtype === "身份证"
    ) {
      const age = getAgeFromIDCard(idCardNum);
      this.basicInformationForm.age = age;
    }
  }
  basicInformationSelectChange(content: string) {
    if (content == "身份证") {
      this.basicInformationRules.user_idcardnum = this.idCardRule as any;
    } else {
      this.basicInformationRules.user_idcardnum = this.passportRule;
    }
    this.basicInformationForm.user_idcardnum = "";
  }
  initCurrentIllnessList() {
    const param = {
      dc_typeid: 699,
    };
    ApiGetllnessList(param).then((res) => {
      if (res.success) {
        this.currentIllnessList = res.data;
      }
    });
  }
  //hook
  mounted() {
    this.init();
    sessionStorage.removeItem("case_scale-view");
    this.initCurrentIllnessList();
  }
  //methods
  init() {
    this.updateTableData();
    this.getTagList();
  }
  getTagList() {
    const param = {};
    ApiLabelList(param).then((res) => {
      //color: 绿0 红1 蓝2
      if (res.success) {
        this.tagList = res.data.map((item: any) => {
          return {
            name: item.name,
            color: item.color,
            value: item.name,
          };
        });
        //数组的浅拷贝
        this.labelList = [].concat(this.tagList as any);
        this.labelList.unshift({
          name: "全部",
          value: "",
        });
      }
    });
  }
  dialogForm = {
    tags: [] as any,
  };
  tagList: any[] = [];
  //添加标签对话框是否显示
  showTagDialog = false;
  //当前点击的病历信息
  currentRow: any = null;
  setColor(val: number) {
    switch (val) {
      case 0:
        return "green";
      case 1:
        return "red";
      case 2:
        return "blue";
    }
  }
  addTag(row: any) {
    this.currentRow = row;
    if (row.tag) {
      row.tag.forEach((item: any) => {
        this.dialogForm.tags.push(item.name);
      });
    }
    //如果没有标签，则打开警示对话框
    if (this.tagList.length == 0) {
      this.confirmDialogVisible = true;
    } else {
      //如果有标签，则打开新增标签对话框
      this.showTagDialog = true;
    }
  }
  gotoTag() {
    breadcrumb.push(this, "/doctor-system/medical/tags", "分组设置", {}, false);
  }
  deleteTag(row: any, index: number) {
    row.tag.splice(index, 1);
    let tagList = null;
    if (row.tag.length == 0) {
      tagList = "[]";
    } else {
      tagList = JSON.stringify(row.tag);
    }

    const param: any = {
      user_id: row.user_id,
      tag: tagList,
    };
    ApiCaseLabelSave(param).then((res) => {
      if (res.success) {
        this.updateTableData();
      }
    });
  }
  //关闭表单对话框函数
  closeFormDialog() {
    this.showTagDialog = false;
    this.dialogForm.tags = [];
  }
  //表单对话框确定按钮点击触发
  completeFormContinue() {
    const temp = JSON.parse(JSON.stringify(this.dialogForm.tags));
    temp.forEach((item: any, idx: number) => {
      if (typeof item == "string") {
        this.tagList.forEach((it: any) => {
          if (it.name == item) {
            const itemDetail = {
              color: it.color,
              name: item,
            };
            temp.splice(idx, 1, itemDetail);
          }
        });
      }
    });
    let tagList = JSON.stringify(temp);
    if (tagList.length == 2) {
      tagList = "[]";
    }
    const param: any = {
      user_id: this.currentRow.user_id,
      tag: tagList,
    };
    ApiCaseLabelSave(param).then((res) => {
      if (res.success) {
        this.updateTableData();
        this.closeFormDialog();
      }
    });
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  userDiseaseFormat(val: string) {
    if (!val) return "-";
    if (val.indexOf("[") > -1) {
      val = val.replace(/\[|\]/g, "");
      val = val.replace(/"/g, "");
      val = val.split(",").join(" ");
      return val;
    } else {
      val = val.split(",").join(" ");
      return val;
    }
  }
  //获取列表数据
  updateTableData() {
    let endTime = this.condition.end_createdate;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      endTime = endTime + timeLimit - 1;
    }
    const param = {
      user_name: this.condition.user_name.trim(),
      user_sex: this.condition.user_sex,
      user_age: this.condition.user_age.replace(/\s/g, ""),
      user_process: this.condition.user_process.trim(),
      start_createdate: this.condition.start_createdate,
      end_createdate: endTime,
      name: this.condition.name,
      user_tel: this.condition.user_tel,
      user_disease: this.condition.user_disease || [],
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    this.tableLoading = true;
    ApiElectronicCaseList(param).then((res) => {
      if (res.success) {
        this.tableData = res.data.map((item: any) => {
          if (!item.tag) {
            item.tag = "";
          } else {
            if (item.tag.length != 0) {
              item.tag = JSON.parse(item.tag);
            }
          }
          return item;
        });
        this.tableLoading = false;
        this.pagination.total = res.page.total_size;
      }
    });
  }
  //点击查询按钮触发的事件
  clickSearch() {
    this.updateTableData();
  }
  //点击重置按钮触发的事件
  resetCondition() {
    //清空查询条件
    Object.keys(this.condition).map((item: any) => {
      this.condition[item] = "";
    });
    //重置分页器
    this.pagination.page = 1;
    this.pagination.size = 10;
    //重置表格数据
    this.updateTableData();
    //重置下拉框数据
    this.getTagList();
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.updateTableData();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.updateTableData();
  }
  //点击查看按钮触发的函数
  clickDetail(row: any) {
    localStorage.setItem("user_name", row.user_name);
    breadcrumb.push(
      this,
      "/doctor-system/medical/view",
      "患者详情",
      {
        user_id: row.user_id,
        disease_age: row.disease_age,
        disease_date: row.disease_date,
      },
      false
    );
  }
  delDetail(row: any) {
    this.$confirm(
      "确认删除该患者吗？删除后所有数据均会删除，不可恢复。",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    ).then(async () => {
      const result = await ApiBasicInformationDel({
        user_id: row.user_id,
        doctor_id: (utils.get("doctorLoginInfo") as any).id,
      });
      if (result.success) {
        this.updateTableData();
        this.$message({
          type: "success",
          message: "删除成功",
        });
      }
    });
  }
  //点击新增病历按钮触发的函数
  clickAdd() {
    // breadcrumb.push(
    //   this,
    //   "/doctor-system/medical/add",
    //   "新增患者",
    //   null,
    //   false
    // );
    this.dialogAdd = true;
  }
  handleKeysBlank(val: any) {
    Object.keys(val).map((item: any) => {
      val[item] = "";
    });
  }
  cancelFun() {
    this.handleKeysBlank(this.basicInformationForm);
    this.basicInformationForm.user_nationality = "汉族";
    (this.$refs.basicInformationRuleForm as Form).clearValidate();
    this.currentAddr = [];
    this.dialogAdd = false;
  }
  //添加标签对话框-前去分组维护按钮触发事件
  goTagManagement() {
    this.closeConfirmDialog();
    breadcrumb.push(
      this,
      "/doctor-system/medical/tags",
      "分组维护",
      null,
      true
    );
  }
  //基础信息编辑-取消确认对话框
  confirmDialogVisible = false;
  closeConfirmDialog() {
    this.confirmDialogVisible = false;
  }
  completeConfirmConfirm() {
    this.goTagManagement();
  }
  provinceList = [] as any;
  cityList = [] as any;
  //获取省下拉数据
  resultprovinceList() {
    ApiGetProvince().then((res: any) => {
      if (res.success) {
        this.provinceList = res.data;
        console.log(this.provinceList);
      }
    });
  }
  //省份change事件
  nationChange(val: any) {
    if (this.basicInformationForm.user_country != "中国") return;
    let objNation = {} as any;
    objNation = this.provinceList.find((item: any) => {
      return item.code === val;
    });
    this.basicInformationForm.user_province = objNation.name;
    const province_id = objNation.code;
    ApiGetCity({ province_code: province_id }).then((res) => {
      this.cityList = res.data;
    });

    this.basicInformationForm.user_city = "";
  }
  //市change事件
  cityChange(val: any) {
    if (this.basicInformationForm.user_country != "中国") return;
    let objCity = {} as any;
    objCity = this.cityList.find((item: any) => {
      return item.code === val;
    });
    this.basicInformationForm.user_city = objCity.name;
  }
  // 点击清空省
  clearProvince() {
    this.basicInformationForm.user_city = "";
    this.basicInformationForm.user_province = "";
    // 设置市为空
    this.cityList = [];
  }
  // 点击清空市
  clearCity() {
    // 设置市为空
    this.basicInformationForm.user_city = "";
    // setTimeout(() => {
    //   // 添加事件队列
    //   const formRef11 = this.$refs["ruleForm"] as any;
    //   formRef11.clearValidate(["province"]);
    //   formRef11.clearValidate(["county"]);
    //   formRef11.clearValidate(["city"]);
    // }, 0);
  }
  countryChangeHandle() {
    if (this.basicInformationForm.user_country == "中国") {
      this.basicInformationForm.user_province = "";
      this.basicInformationForm.user_city = "";
      this.resultprovinceList();
    } else if (this.basicInformationForm.user_country == "澳大利亚") {
      this.basicInformationForm.user_province = "澳大利亚";
      this.basicInformationForm.user_city = "澳大利亚";
      this.provinceList = [
        {
          code: "100000080",
          first_letter: "A",
          name: "澳大利亚",
        },
      ];
      this.cityList = [
        {
          province_code: "100000080",
          code: "1000000801",
          first_letter: "A",
          name: "澳大利亚",
        },
      ];
    } else if (this.basicInformationForm.user_country == "新加坡") {
      this.basicInformationForm.user_province = "新加坡";
      this.basicInformationForm.user_city = "新加坡";
      this.provinceList = [
        {
          code: "100000081",
          first_letter: "X",
          name: "新加坡",
        },
      ];
      this.cityList = [
        {
          province_code: "100000081",
          code: "1000000811",
          first_letter: "X",
          name: "新加坡",
        },
      ];
    }
  }

  ageInputHandle() {
    if (isNaN(this.basicInformationForm.age)) {
      this.$message.error("请输入大于等于0的正整数。");
      this.basicInformationForm.age = "";
      return;
    }
    if (Number(this.basicInformationForm.age) < 0) {
      this.$message.error("请输入大于等于0的正整数。");
      this.basicInformationForm.age = "";
      return;
    }
  }
}
